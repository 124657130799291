export const addMeta = () => {
  // should be server side, but cannot determine host in cloudfront lambda
  if (
    window &&
    window.location &&
    window.location.hostname &&
    window.location.hostname.search(/orumcartoons\.com|localhost/) === -1
  ) {
    var meta = document.createElement("meta")
    meta.name = "robots"
    meta.content = "noindex"
    document.getElementsByTagName("head")[0].appendChild(meta)

    // redirect from cloudfront distribution
    window.location.href = `https://www.orumcartoons.com${window.location.pathname}`
  }
}
