import React from "react"
import { Link, graphql } from "gatsby"

import { FaChevronRight, FaChevronLeft } from "react-icons/fa"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import { addMeta } from "../utils/meta"

class BlogPostTemplate extends React.Component {
  componentDidMount() {
    addMeta()
  }

  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const avatar = this.props.data.avatar
    const { previous, next } = this.props.pageContext

    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        avatar={avatar}
        shirt={post.frontmatter.shirt}
      >
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          url={this.props.location.pathname}
        />
        <article>
          <header>
            <h4
              style={{
                marginTop: "1rem",
                marginBottom: 0,
                fontFamily: "Indie Flower",
              }}
            >
              {post.frontmatter.title}
            </h4>
            <p
              style={{
                // ...scale(-1 / 5),
                display: `block`,
                marginBottom: rhythm(1),
                fontSize: "60%",
              }}
            >
              {post.frontmatter.date}
            </p>
          </header>
          <section dangerouslySetInnerHTML={{ __html: post.html }} />
          <hr
            style={{
              marginBottom: rhythm(1),
            }}
          />
        </article>

        <nav>
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
              margin: 0,
            }}
          >
            <li>
              {next && (
                <Link to={next.fields.slug} rel="next">
                  <FaChevronLeft /> {next.frontmatter.title}
                </Link>
              )}
            </li>
            <li>
              {previous && (
                <Link to={previous.fields.slug} rel="prev">
                  {previous.frontmatter.title} <FaChevronRight />
                </Link>
              )}
            </li>
          </ul>
        </nav>

        <footer style={{ marginTop: "2rem" }}>
          <Bio />
        </footer>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    avatar: file(absolutePath: { regex: "/pjo-icon.png/" }) {
      childImageSharp {
        fixed(width: 50, height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        shirt
      }
    }
  }
`
